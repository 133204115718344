import Axios from 'axios';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Joi from '@hapi/joi';
import Analytics from '../windowAnalytics';

import MyStorage from '../storage';
import TrackableReact from '../trackableReact';
import ErrorToast from './errorToast'
import { signInToFirebase } from '../firebaseFns'
import SecondaryAuthentication from '../secondaryAuthentication';
import PasswordField from '../PasswordField';

const internals = {};
internals.loginRules = Joi.object({
  email: Joi.string().min(1),
  password: Joi.string().min(1)
});


export default class Profile extends TrackableReact {
  constructor(props) {
    super(props);

    this.state = {
      login: {
        email: '',
        password: ''
      },
      redirect: {
        error: false,
        statusCode: 0,
      },
      pwreset: false,
      mode: 'login',
      errs: null,
      saving: false,
      multiFactorResolver: null
    };

    this.login = this.login.bind(this);
    this.firebaseAuthentication = this.firebaseAuthentication.bind(this);
    this.updateInput = this.updateInput.bind(this);
    this.renderProfileForm = this.renderProfileForm.bind(this);
    this.renderSecondaryAuthentication = this.renderSecondaryAuthentication.bind(this);
    this.renderForgotPWForm = this.renderForgotPWForm.bind(this);
    this.resetPW = this.resetPW.bind(this);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const [error, status] = [urlParams.get('error'), urlParams.get('status')];
    if (error && status) this.setState({ redirect: { error, statusCode: status }});
  }

  async login(user, credentials) {
    if (!user?.accessToken && !credentials) {
      this.setState({errs: "Invalid password", saving: false});
      return;
    }
    const loginType = user ? 'firebase' : credentials ? 'email' : 'none';
    const loginPayload =
      loginType === 'firebase' ? {firebaseToken: user.accessToken || ''} :
      loginType === 'email' ? {email: credentials.email, password: credentials.password} :
      null;
    if (!loginPayload) {
      this.setState({ errs: 'Invalid password', saving: false });
      return;
    }
    Axios.post(`/api/login`, loginPayload)
      .then(({ data }) => {
        window.localStorage.setItem('loginType', loginType);
        MyStorage.set('profile', data);
        this.setState({ saving: false });
        this.identify(data.userId, data);
        window.location = '/';
      }) // handle success
      .catch((err) => {
        console.error(err);
        this.setState({ errs: err.response.data, saving: false });
      }); // handle error
  }

  async firebaseAuthentication(e) {
    e.preventDefault();

    const valErrs = internals.loginRules.validate(this.state.login);
    console.log(valErrs);
    if (valErrs.error) return this.setState({ errs: "form required" });

    this.setState({ saving: true, errs: null });
    const firebaseResponse = await signInToFirebase(this.state.login.email, this.state.login.password);
    if (!firebaseResponse) {
      this.login(null, {email: this.state.login.email, password: this.state.login.password});
      return;
    }
    switch(firebaseResponse.type) {
      case 'multiFactor':
        this.setState({
          saving: false,
          mode: 'mfa',
          multiFactorResolver: firebaseResponse.data
        });
        break;
      case 'credential':
        this.login(firebaseResponse.data?.user || {});
        break;
      default:
        console.log('Unexpected resposne type')
        break;
    }
  }

  resetPW(e) {
    e.preventDefault();

    if(!this.state.login.email) return this.setState({ pwreset: "need email" });
    else this.setState({ saving: true });

    Axios.post(`/api/pwreset`, this.state.login)
      .then(({ data }) => {
        this.setState({
          pwreset: "sent",
          saving: false,
          login: { email: '', password: '' }
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({ pwreset: err.response.data, saving: false });
      });
  }

  updateInput(e) {
    const target = e.target;
    const login = Object.assign({}, this.state.login);

    login[target.name] = target.value;
    this.setState({ login });
  }

  renderErrorMessage(errMessage) {
    if (!errMessage) return null;

    let message;

    switch(errMessage) {
      case "form required":
        message = <p>Email and password are required</p>;
        break;
      case "User not found":
        message = <p>That email was not found.</p>;
        break;
      case "Invalid password":
        message = <p>Your password is incorrect.</p>;
        break;
      default:
        message = <p>Unfortunately we could not log you in and we honestly don't know why. Please reachout to <a href="mailto:support@audirie.com">support@Audirie.com</a> if the problem persists.</p>
    }

    return <div className="text-danger">{ message }</div>;
  }

  renderProfileForm() {
    const login = this.state.login;
    const valErrs = this.state.errs;
    return (
      <form action="#" onSubmit={this.firebaseAuthentication}>
        {this.renderErrorMessage(valErrs)}
        <div className="form-floating">
          <input
            id="email-input"
            className="form-control"
            name="email"
            type="email"
            placeholder="name@example.com"
            value={login.email}
            onChange={this.updateInput} />
          <label htmlFor="email-input" className="form-label">Email</label>
        </div>
        <PasswordField
          id="password-input"
          label="Password"
          name="password"
          passwordValue={login.password}
          updateInput={this.updateInput}
        />
        <div className="row">
          <div className="col-7">
            <a
              className="btn btn-link"
              onClick={(e) => {e.preventDefault(); this.setState({ mode: 'forgot-pw' });}}
            >
              Forgot Password
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle ml-2" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
              </svg>
            </a>
          </div>
          <div className="col text-right">
            { this.state.saving ? <Spinner className="mr-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
            <button className="btn btn-primary" type="submit">Login</button>
          </div>
        </div>
      </form>
    );
  }

  renderSecondaryAuthentication() {
    return (
      <SecondaryAuthentication
        resolver={this.state.multiFactorResolver}
        onEnd={(userCredential) => { this.login(userCredential?.user) }}
      />
    )
  }

  renderForgotPWForm() {
    let genericMessage;
    switch(this.state.pwreset) {
      case "sent":
        genericMessage = <p className="text-success">If the email address you gave is in our records, you will receive an email with a link to reset your password.</p>;
        break;
      case "need email":
        genericMessage = <p className="text-danger">Please give us an valid email.</p>;
        break;
      case false:
        genericMessage = null;
        break;
      default:
        genericMessage = <p className="text-danger">We could not save your updated profile. Please try again. If this persists please contact <a href="mailto:support@audirie.com">Support@Audirie.com</a></p>;
    }

    return (
      <form action="#" method="POST" onSubmit={this.resetPW} noValidate>
        {genericMessage}
        <div className="g-2">
          <input
            id="email-input"
            className={`form-control`}
            name="email"
            type="email"
            placeholder="name@example.com"
            value={this.state.login.email}
            onChange={this.updateInput} />
          <label htmlFor="email-input" className="form-label">Email</label>
        </div>

        <div className="row">
          <div className="col">
            <a
              className="btn btn-link text-left"
              onClick={(e) => {e.preventDefault(); this.setState({ mode: 'login' });}}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-circle mr-2" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
              </svg>
              Cancel
            </a>
          </div>
          <div className="col text-right">
            { this.state.saving ? <Spinner className="mr-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
            <button disabled={this.state.saving} className="btn btn-primary" type="submit">Send</button>
          </div>
        </div>
      </form>
    );
  }

  render() {
    if (location.search?.indexOf('aid') > -1) {
      let searchParam = "return=";
      let decoded = decodeURIComponent(location.search);
      let returnUrl = decoded.substring(decoded.indexOf(searchParam) + searchParam.length);
      window.location.href = returnUrl;
      return;
    }
    return (
      <div className="container">
        <div className="row justify-content-center mt-3 mb-n4">
          <ErrorToast
            error={this.state.redirect.error}
            statusCode={this.state.redirect.statusCode}
          />
        </div>
        <div className="row justify-content-center mt-5">
          <div id="LoginForm" className="col-4 rounded px-4 py-3">
            <div className="row justify-content-center pb-4">
              <img
                src="/static/images/audirieLogo.png"
                width="50%"
                className="d-inline-block align-top"
                alt="Audirie"
                />
            </div>
            <div className="row">
              <div className="col gap-2">
              { this.state.mode === 'forgot-pw' ?
                  this.renderForgotPWForm() :
                this.state.mode === 'mfa' ?
                  this.renderSecondaryAuthentication() :
                  this.renderProfileForm() }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
